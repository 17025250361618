import React from "react";
// import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import CallToAction from "../components/Repeating/CTA";
// import useWindowSize from "../windowSize";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
  // const windowSize = useWindowSize();
  // const [mapZoomm, setMapZoomm] = useState(5);

  // useEffect(() => {
  //   if (windowSize.width > 768) {
  //     setMapZoomm(5);
  //     console.log(mapZoomm);
  //   } else {
  //     setMapZoomm(7);
  //     console.log(mapZoomm);
  //   }
  // }, [windowSize, mapZoomm]);

  const stateNodes = data && data.states && mapEdgesToNodes(data.states);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Locations | Right Start | Mortgage Lender"
        description="At Right Start, we serve eight states, with areas as diverse as Tempe, Arizona, Las Vegas, Nevada, and San Bernardino, California. Contact us to get started!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-32">
        {typeof window !== "undefined" && (
          <>
            <MapContainer
              center={[40.0, -101.299591]}
              zoom={5}
              className="z-0 hidden md:block md:h-[400px] lg:h-[480px] xl:h-[520px] 2xl:h-[640px]"
              scrollWheelZoom={false}
            >
              <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=FSveQszL6K6YXmjxoETU" />
              {stateNodes.map((state, index) => {
                return (
                  <Marker
                    key={index}
                    position={[
                      Number(state.coordinates.latitude),
                      Number(state.coordinates.longitude),
                    ]}
                  >
                    <Popup>
                      <Link
                        to={`/${state.slug.current}/`}
                        className="no-underline"
                      >
                        {state.title}
                      </Link>
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>

            <MapContainer
              center={[38.25, -101.299591]}
              zoom={3}
              className="z-0 h-[280px] md:hidden"
              scrollWheelZoom={false}
            >
              <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=FSveQszL6K6YXmjxoETU" />
              {stateNodes.map((state, index) => {
                return (
                  <Marker
                    key={index}
                    position={[
                      Number(state.coordinates.latitude),
                      Number(state.coordinates.longitude),
                    ]}
                  >
                    <Popup>
                      <Link
                        to={`/${state.slug.current}/`}
                        className="no-underline"
                      >
                        {state.title}
                      </Link>
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </>
        )}
      </section>

      <StatesWeServe />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    states: allSanityStates(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug {
            current
          }
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export default Page;
